import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

const propTypes = {}

const defaultProps = {}

function Profile () {
  return (
    <div>
      <p>
        This is the Profile view. You can change what's rendered here by editing the file: <b>src/js/main/user/views/Profile.js</b>
      </p>
    </div>
  )
}

Profile.propTypes = propTypes

Profile.defaultProps = defaultProps

function mapStateToProps () {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Profile)
