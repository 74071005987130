import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  votes: PropTypes.array,
}

const defaultProps = {}

function VotingCallout ({ title, body, votes }) {
  const voteRecipients = votes && votes.length && votes.map(v => v.recipient).join(', ')
  return (
    <div className="voting-callout">
      <h1>{ title }</h1>
      { body && <p>{ body }</p> }
      { voteRecipients && <p className="vote-detail-message">You voted for {voteRecipients}</p>}
    </div>
  )
}

VotingCallout.propTypes = propTypes

VotingCallout.defaultProps = defaultProps

export default VotingCallout
