import React from 'react'

const propTypes = {}

const defaultProps = {}

function LoadingIndicator () {
  return (
    <div className="page-spinner">
      <div id="spinner"></div>
    </div>
  )
}

LoadingIndicator.propTypes = propTypes

LoadingIndicator.defaultProps = defaultProps

export default LoadingIndicator
