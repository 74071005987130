import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { onMount, modifyProps, waitForResponse } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { LoadingIndicator } from 'components'
import { VoteForm } from '../forms'
import { ClosedVote, VotingCallout } from '../components'
import * as effects from 'effects'
import graphic from '../../../../images/astro/star-background.svg'
import graphic2 from '../../../../images/astro/astro.svg'
import { flatMap } from 'lodash'

const propTypes = {
	vote: PropTypes.object,
	token: PropTypes.string,
	submitVote: PropTypes.func,
	users: PropTypes.array,
	votingIsOpen: PropTypes.bool,
	votingIsClosed: PropTypes.bool,
	voteError: PropTypes.string,
	allowMoreVotes: PropTypes.bool,
}

const defaultProps = {}

function Vote({
	vote,
	submitVote,
	users,
	allowMoreVotes,
	votingIsOpen,
	votingIsClosed,
	voteError,
	token,
}) {
	return (
		<div className="content-kudos-wrapper">
			<div className="navigation">
				<span>kudos</span>
			</div>
			<div className="content-kudos-container">
				{votingIsOpen && (
					<VoteForm
						onSubmit={submitVote}
						users={users}
						vote={vote}
						allowMoreVotes={allowMoreVotes}
						token={token}
						initialValues={{
							votes: [{ id: window.crypto.randomUUID() }],
						}}
					/>
				)}
				{votingIsClosed && (
					<ClosedVote resultTime={vote.resultTime} votes={vote.votes} />
				)}
				{voteError && <VotingCallout title="Oops!" body={voteError} />}
				<div className="image-block">
					<div className="graphic">
						<img src={graphic} alt="" />
						<img src={graphic2} alt="" className="animated-graphic" />
					</div>
				</div>
			</div>
		</div>
	)
}

Vote.propTypes = propTypes

Vote.defaultProps = defaultProps

function mapStateToProps(
	state,
	{ location: { query }, params: { id, orgId } }
) {
	const formSelector = formValueSelector('vote-form')
	return {
		vote: selectors.vote(state),
		voteError: selectors.voteError(state),
		users: selectors.users(state),
		voteId: Number(id),
		organizationId: Number(orgId),
		token: query.token,
		currentVotes: formSelector(state, 'votes'),
	}
}

const mapDispatchToProps = {
	fetchVote: apiActions.fetchVote,
}

function setup({ organizationId, voteId, token, fetchVote }) {
	fetchVote({ organizationId, id: voteId, token })
}

function sendVoteParams({ organizationId, vote, token, params }) {
	return effects.updateVote({ organizationId, vote, token, params })
}

function modify({
	vote,
	token,
	users,
	organizationId,
	currentVotes,
	voteError,
}) {
	return {
		submitVote: (params) => {
			const allVotes = flatMap(params.votes, ({ userIds, valueId, comment }) =>
				userIds.map((userId) => ({ userId, valueId, comment }))
			)
			return sendVoteParams({
				organizationId,
				vote,
				token,
				params: { ...params, votes: allVotes },
			})
		},
		votingIsOpen: !voteError && vote && vote.votingOpen,
		votingIsClosed: !voteError && vote && !vote.votingOpen,
		allowMoreVotes:
			!currentVotes || users.length > flatMap(currentVotes, 'userIds').length,
	}
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	onMount(setup),
	waitForResponse(apiActions.REQ_VOTE, LoadingIndicator),
	modifyProps(modify)
)(Vote)
