import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'
import Layout from './Layout'

const Routes = (
  <Route component={ Layout }>
    <Route path="/organizations/:orgId/votes/:id/thank-you" component={ Views.ThankYou } />
    <Route path="/organizations/:orgId/votes/:id" component={ Views.Vote } />
  </Route>
)

export default Routes
