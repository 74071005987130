import { combineReducers } from 'redux'
import {
  reducer as voteReducer,
  reducerKey as voteReducerKey,
} from './vote'

const reducerKey = 'root'

const reducer = combineReducers({
  [voteReducerKey]: voteReducer,
})

export { reducer, reducerKey }
