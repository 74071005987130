import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { onMount, waitForResponse } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { LoadingIndicator } from 'components'
import star from '../../../../images/astro/astro-star.svg'
import cannon from '../../../../images/astro/cannon.svg'
import confetti from 'canvas-confetti'

const propTypes = {
	vote: PropTypes.object,
	voteError: PropTypes.string,
}

const defaultProps = {}

function ThankYou({ vote, vote: { resultTime, votes }, voteError }) {
	const [isConfettiLeftActive, setConfettiLeftActive] = useState(false)
	const [isConfettiRightActive, setConfettiRightActive] = useState(false)

	const startConfettiLeft = () => {
		if (!isConfettiLeftActive) {
			setConfettiLeftActive(true)

			// Your confetti animation settings
			var end = Date.now() + 2000 // 2 seconds
			var colors = ['F2C32B', '1E60BD', 'B91759']

			;(function frame() {
				confetti({
					particleCount: 3,
					shapes: ['square'],
					angle: 60,
					spread: 55,
					origin: { x: 0.07, y: 0.9 },
					colors: colors,
					scalar: 2.5,
					zIndex: 2,
				})

				if (Date.now() < end) {
					requestAnimationFrame(frame)
				} else {
					setConfettiLeftActive(false) // Reset the flag when animation is complete
				}
			})()
		}
	}

	const startConfettiRight = () => {
		if (!isConfettiRightActive) {
			setConfettiRightActive(true)

			// Your confetti animation settings
			var end = Date.now() + 2000 // 2 seconds
			var colors = ['F2C32B', '1E60BD', 'B91759']

			;(function frame() {
				confetti({
					particleCount: 3,
					shapes: ['square'],
					angle: 120,
					spread: 55,
					origin: { x: 0.95, y: 0.9 },
					colors: colors,
					scalar: 2.5,
					zIndex: 2,
				})

				if (Date.now() < end) {
					requestAnimationFrame(frame)
				} else {
					setConfettiRightActive(false) // Reset the flag when animation is complete
				}
			})()
		}
	}

	useEffect(() => {
		// Call the startConfetti function on page load
		startConfettiLeft()
		startConfettiRight()
	}, [])

	return (
		<div className="thank-you-wrapper">
			<div className="thank-you-kudos-container">
				{vote && !voteError && (
					<div>
						<div className="navigation">
							<span>kudos</span>
						</div>
						<header>
							<h1>Thanks for Voting!</h1>
							<p className="result-time">
								Stay tuned — we’ll announce the winner at {resultTime}!
							</p>
						</header>
						<div className="thank-you-block">
							{votes.map((v) => (
								<div className="card" key={v.recipient}>
									<div className="image-block">
										<img src={star} alt="" />
									</div>
									<div className="text-block">
										<p className="name">{v.recipient}</p>
										<p className="title">
											{v.recipientTitle || v.organizationName}
										</p>
										{(v.comment || v.valueName) && (
											<p className="comment">
												{v.comment} {v.valueName}
											</p>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				{voteError && (
					<div className="thank-you-block">
						<h1>Oops!</h1>
						<p>{voteError}</p>
					</div>
				)}
			</div>
			<footer></footer>
			<button
				type="button"
				className="confetti-cannon left"
				onClick={startConfettiLeft}
			>
				<img src={cannon} alt="" />
			</button>
			<button
				type="button"
				className="confetti-cannon right"
				onClick={startConfettiRight}
			>
				<img src={cannon} alt="" />
			</button>
		</div>
	)
}

ThankYou.propTypes = propTypes

ThankYou.defaultProps = defaultProps

function mapStateToProps(
	state,
	{ location: { query }, params: { orgId, id } }
) {
	return {
		vote: selectors.vote(state),
		voteError: selectors.voteError(state),
		voteId: Number(id),
		organizationId: Number(orgId),
		token: query.token,
	}
}

const mapDispatchToProps = {
	fetchVote: apiActions.fetchVote,
}

function setup({ organizationId, voteId, token, fetchVote }) {
	fetchVote({ organizationId, id: voteId, token })
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	onMount(setup),
	waitForResponse(apiActions.REQ_VOTE, LoadingIndicator)
)(ThankYou)
