import { configureApi } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
export const API_URL = process.env.API_URL

// Configure middleware and api services

export const config = {
  root: API_URL,
  mode: 'cors',
  // successDataPath: 'data.attributes',
}

export const middleware = configureMiddleware(config)
export const api = configureApi(config)
