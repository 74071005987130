import React from 'react'
import PropTypes from 'prop-types'
import { VotingCallout } from './'

const propTypes = {
  resultTime: PropTypes.string,
  votes: PropTypes.array,
}

const defaultProps = {}

function ClosedVote ({ resultTime, votes }) {
  const body = `${status} Poll closure at ${resultTime}.`
  return <VotingCallout title="Voting closed!" body={ body } votes={votes} />
}

ClosedVote.propTypes = propTypes

ClosedVote.defaultProps = defaultProps

export default ClosedVote
