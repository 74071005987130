import React from 'react'

const propTypes = {}

const defaultProps = {}

function NotFound () {
  return (
    <div>Not Found</div>
  )
}

NotFound.propTypes = propTypes

NotFound.defaultProps = defaultProps

export default NotFound
