import { handleActions } from 'redux-actions'
import { setFromRequest } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import { REQ_VOTE } from 'api-actions'

const reducerKey = 'vote'
const slice = 'root.vote'

const initialState = {
  vote: {},
  voteError: null,
}

const reducer = handleActions({
  ...setFromRequest(REQ_VOTE, 'vote'),
}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  vote: select('vote.success.data.attributes', {}),
  voteError: select('vote.failure.response.errors'),
}

selectors.users = function (state) {
  const vote = selectors.vote(state)
  return vote.users || []
}

export { reducer, selectors, reducerKey }
