import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
	LabeledField,
	fieldPropTypesWithValue,
	hasInputError,
} from 'lp-components'
import Select from 'react-select'

const propTypes = {
	...fieldPropTypesWithValue(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
	),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	options: PropTypes.arrayOf(Types.userOption),
	isMulti: PropTypes.bool,
}

const defaultProps = {
	label: '',
	options: [],
	isMulti: false,
}

function SearchableSelect({ input, meta, label, isMulti, options, ...rest }) {
	const selectRef = useRef() // workaround to ensure onBlur is invoked with the latest value (blur & change actions seem to be called simultaneously when on mobile)
	const hasError = hasInputError(meta)

	const onChangeHandler = (option, { action }) => {
		if (isMulti) {
			const values = option.map((item) => item.value)
			selectRef.current.value = values
			return input.onChange(values)
		}
		if (action === 'clear') {
			selectRef.current.value = null
			return input.onChange(null)
		}
		selectRef.current.value = option.value
		return input.onChange(option.value)
	}

	return (
		<LabeledField
			{...{
				input,
				meta,
				label,
				...rest,
			}}
		>
			<Select
				ref={selectRef}
				id={input.name}
				options={options}
				isMulti={isMulti}
				onChange={onChangeHandler}
				onBlur={() => input.onBlur(selectRef.current ? selectRef.current.value : null)}// trigger onBlur with the current value to avoid clearing the field state
				aria-describedby={hasError ? `${input.name}-error` : null}
				{...rest}
				styles={{
					control: (styles, state) => ({
						...styles,
						borderRadius: 5,
						borderColor:
							state.menuIsOpen || state.isFocused
								? '#767676' // $grey-dark
								: hasError
								? '#b30016' // $red-dark
								: '#dfe1e6', // $white-med
						borderWidth: 2,
						minHeight: 51,
						'&:hover': 'none',
						boxShadow: 'none',
					}),
					menu: (styles) => ({ ...styles, marginTop: 4 }),
					option: (styles, state) => ({
						...styles,
						':active': { backgroundColor: 'none' },
						backgroundColor: state.isSelected
							? '#f1f1f1' // $grey-med
							: state.isFocused
							? '#F4F6F6' // $white-light
							: 'inherit',
						color: 'inherit',
					}),
					multiValue: (styles) => ({
						...styles,
						backgroundColor: '#f1f1f1', // $grey-med
					}),
					multiValueLabel: (styles) => ({ ...styles, fontSize: '100%' }),
					placeholder: (styles) => ({
						...styles,
						fontStyle: 'italic',
					}),
				}}
			/>
		</LabeledField>
	)
}

SearchableSelect.propTypes = propTypes
SearchableSelect.defaultProps = defaultProps

export default SearchableSelect
