import { requestWithKey } from 'lp-redux-api'

export const REQ_VOTE = 'REQ_VOTE'
export const REQ_USER = 'REQ_USER'

export function fetchVote ({ organizationId, id, token }) {
  const url = `/organizations/${ organizationId }/contest_votes/${ id }`
  return requestWithKey(REQ_VOTE, { url, query: { token }, mode: 'cors' })
}

export function fetchUser ({ id }) {
  const url = `/users/${ id }`
  return requestWithKey(REQ_USER, { url, mode: 'cors' })
}
