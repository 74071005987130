import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'
import Layout from './Layout'

const Routes = (
  <Route component={ Layout }>
    <Route path="/update-profile" component={ Views.UpdateProfile } />
    <Route path="/profile" component={ Views.Profile } />
  </Route>
)

export default Routes
